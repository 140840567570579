<script lang="ts" setup>
import { ref, computed, getCurrentInstance } from "vue";
import PermissionCategoriesRelationship from "@/components/iam/PermissionCategoriesRelationship.vue";
import { iamType, addRelationship, removeRelationship } from "@/services/api/iam.api";

const props = defineProps({
  permissionKey: {
    type: String,
    default: undefined,
  },
});

const refreshKey = ref(0);

const accessSelection = computed(() => {
  return { domain: iamType.Permission, id: props.permissionKey };
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function onAddRelationship(selection) {
  const response = await addRelationship(iamType.Permission, props.permissionKey, selection);
  if (response) {
    if (!response.error) {
      refreshKey.value++;
    } else {
      proxy.$toaster.error("Toewijzen van de recht aan een categorie is mislukt! " + response.error);
    }
  }
}

async function onRemoveRelationship(id) {
  const response = await removeRelationship(iamType.Permission, props.permissionKey, iamType.PermissionCategory, id);
  if (response) {
    if (!response.error) {
      refreshKey.value++;
    } else {
      proxy.$toaster.error("Verwijder van de recht aan een categorie is mislukt! " + response.error);
    }
  }
}
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <permission-categories-relationship
      @add-relationship="onAddRelationship"
      allow-remove="true"
      @remove-relationship="onRemoveRelationship"
      :refreshKey="refreshKey"
      :viaAccess="accessSelection"
    ></permission-categories-relationship>
  </v-container>
</template>
